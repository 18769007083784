import React from "react";
import OrderState from "./OrderState";

const OrderDetail = () => {


  return (
    <div>
      <OrderState />
    </div>
  )
};

export default OrderDetail;
