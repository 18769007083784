import React from 'react';
import Resources from '../Components/Cards/Resources/Resources';

const ResourcesPage = () => {
	return (
		<>
			<Resources />
		</>
	);
};

export default ResourcesPage;
