
import React from "react";
import ErrorComponent from "../Components/ErrorComponent/ErrorComponent"

const ErrorPage = () => {
    return (
        <ErrorComponent/>
    );
}
 
export default ErrorPage;